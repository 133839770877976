.matero-page-header {
  display: block;
  padding: 16px;
  margin: -16px -16px 16px;
  color: #fff;
  background-color: #3f51b5;

  .matero-breadcrumb {
    margin-top: 8px;
    margin-bottom: 0;
  }
}

.matero-page-title {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
}

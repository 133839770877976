@use 'long-shadow';

.matero-error-wrap {
  text-align: center;
}

.matero-error-code {
  padding: 20px 0;
  font-size: 150px;
  text-shadow:
    long-shadow.long-shadow(
      $direction: 45deg,
      $length: 60px,
      $color: rgba(0, 0, 0, .03),
      $fade: rgba(0, 0, 0, .0015),
      $shadow-count: 20
    );
}

.matero-error-title {
  margin: 0 0 16px;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

.matero-error-message {
  margin: 0 0 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}
